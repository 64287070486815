<template>
  <div class="wrapper">
    <Toast v-if="alarmas" @click="visible = !visible;test()" v-model="isVisible" ref="tost" />
    <Alarmas :alarmas="alarmas" v-on:actualizarAlarmas="cargaralarmas" :visible="visible"></Alarmas>
    <Toast v-if="novedades" @click="visiblenovedades = !visiblenovedades;test()" v-model="isVisibleNovedades" ref="tostnovedades" />
    <Novedades :novedades="novedades" v-on:actualizarNovedades="cargarnovedades" :visiblenovedades="visiblenovedades"></Novedades>
    <the-top :longitud="longitud" :longitudnovedades="longitudnovedades" :trabajos="trabajos" :numero_trabajos="numero_trabajos" :solicitudes="solicitudes"
      :numero_solicitudes="numero_solicitudes" :asignaciones="asignaciones" :whatsapps="whatsapps"
      :numero_asignaciones="numero_asignaciones" :chats="chats" :waActivado="waActivado"
      :payload="payload"></the-top>
    <the-sidebar :menu="menu"></the-sidebar>
    <div class="content-wrapper">
      <!-- De momento, no mostramos la cabecera con el breadcrumbs para el sistema de multiventanas -->
      <the-content-header v-if="false"></the-content-header>
      <p style="font-size:1px" v-if="extendido!=true">&nbsp;</p>
      <div class="card flex" v-if="extendido==true">
        <p style="font-size:5px">&nbsp;</p>
        <div class="row" v-if="permisosusuario.includes('Plataforma')">
          <div class="col-md-4">
            <div class="row" style="border-bottom: 1px solid;">
              <div class="col-md-5"></div>
              <b style="text-align:center;margin-right: 10px;">Asignaciones</b>
              <input @click="cambiarUrgentes" v-model="asignacionesUrgentes" type="checkbox" title="Filtro de asignaciones urgentes">
            </div>
            <div class="row" style="overflow: auto; height:200px;border: 1px solid">
              <ListaAsigaciones :asignaciones="asignaciones" />
            </div>
          </div>
          <div class="col-md-4">
            <div class="row" style="border-bottom: 1px solid;">
              <div class="col-md-5"></div>
              <b style="text-align:center">Siguientes citas</b>
            </div>
            <div class="row" style="overflow: auto; height:200px;border: 1px solid">
              <ListaSiguientesCitas @actualizarCitas="cargarSiguientes" :siguientesCitas="siguientesCitas" />
            </div>
          </div>
          <div class="col-md-4">
            <div class="row" style="border-bottom: 1px solid;">
              <div class="col-md-5"></div>
              <b style="text-align:center">Citas confirmadas</b>
            </div>
            <div class="row" style="overflow: auto; height:200px;border: 1px solid">
              <ListaCitasConfirmadas @actualizarCitas="cargarConfirmadas" :citasConfirmadas="confirmadas" />
            </div>
          </div>
        </div>
        <div class="row" v-else>
          <div class="col-md-4">
            <div class="row" style="border-bottom: 1px solid;">
              <div class="col-md-5"></div>
              <b style="text-align:center;margin-right: 10px;">Asignaciones</b>
              <input @click="cambiarUrgentes" v-model="asignacionesUrgentes" type="checkbox" title="Filtro de asignaciones urgentes">
            </div>
            <div class="row" style="overflow: auto; height:200px;border: 1px solid">
              <ListaAsigaciones :asignaciones="asignaciones" />
            </div>
          </div>
          <div class="col-md-4">
            <div class="row" style="border-bottom: 1px solid;">
              <div class="col-md-5"></div>
              <b style="text-align:center;">Solicitudes</b>
            </div>
            <div class="row" style="overflow: auto; height:200px;border: 1px solid">
              <ListaSolicitudes :todassolicitudes="todassolicitudes" :solicitudes="solicitudes" />
            </div>
          </div>
          <div class="col-md-4" :style="estilo">
            <div class="row" style="border-bottom: 1px solid;">
              <div class="col-md-5"></div>
              <b style="text-align:center;">Trabajos NO revisados</b>
            </div>
            <div class="row" style="overflow: auto; height:200px;border: 1px solid">
              <ListaTrabajos :todostrabajos="todostrabajos" :trabajos="trabajos" />
            </div>
          </div>
        </div>
      </div>
      <div class="content">
        <div class="container-fluid">
          <multi-ventanas>
            <router-view v-slot="{ Component }">
              <transition name="fade">
                <keep-alive :max="5">
                  <component :is="Component" />
                </keep-alive>
              </transition>
            </router-view>
          </multi-ventanas>
        </div>
      </div>
    </div>
    <the-footer></the-footer>
  </div>
</template>
<script>
import TheTop from './TheTop.vue'
import TheSidebar from './TheSidebar.vue'
import TheFooter from './TheFooter.vue'
import TheContentHeader from './TheContentHeader.vue'
import MultiVentanas from './multiventanas/MultiVentanas.vue'
import { PwgsApi } from "../services/PwgsApi";
import Alarmas from './Alarmas.vue';
import Novedades from './Novedades.vue';
import ListaAsigaciones from './pages/menus/superior/asignaciones/AsignacionesLista.vue';
import ListaTrabajos from './pages/menus/superior/TrabajosNoRevisados/TrabajosNoRevisadosLista.vue';
import ListaSolicitudes from './pages/menus/superior/solicitudes/SolicitudesLista.vue';
import ListaSiguientesCitas from './pages/menus/superior/siguientesCitas/siguientesCitasLista.vue';
import ListaCitasConfirmadas from './pages/menus/superior/citasConfirmadas/citasConfirmadasLista.vue';
import { onMessage, messaging } from '@/firebase';


export default {
  components: {
    TheTop,
    TheSidebar,
    TheFooter,
    TheContentHeader,
    MultiVentanas,
    Alarmas,
    Novedades,
    ListaAsigaciones,
    ListaTrabajos,
    ListaSolicitudes,
    ListaSiguientesCitas,
    ListaCitasConfirmadas
  },
  data() {
    return {
      whatsapps:[],
      waActivado:false,
      chats:0,
      menu: [],
      alarmas: '',
      novedades: '',
      longitud: 0,
      longitudnovedades: 0,
      visible: false,
      isVisible: true,
      visiblenovedades: false,
      isVisibleNovedades: true,
      numero_confirmadas:'',
      confirmadas:[],
      trabajos: [],
      numero_trabajos: '',
      solicitudes: [],
      numero_solicitudes: '',
      asignaciones: [],
      numero_asignaciones: '',
      extendido: true,
      asignacionesUrgentes: false,
      payload: null,
      permisosusuario:[],
      siguientesCitas: [],
      todassolicitudes: false,
      todostrabajos: false,
    }
  },
  methods: {
    async comprobarWa(){
      const api = new PwgsApi();
      var wa = await api.get('parametros-pwgs/whatsapp');
      if(wa.whatsapp=='1'){
        this.waActivado = true;
        let whats = await api.get('chat-pwgs/whatsapp');
        this.whatsapps = whats.datos;
      }else{
        this.waActivado = false;
        this.whatsapps = [];
      }
    },
    async comprobarChatsNuevo() {
        const api = new PwgsApi();
        var resp = await api.get('chat-pwgs');
        if(resp.n_total_registros > this.chats){
            const audio = new Audio(require('@/assets/sonidos/bell-blip.wav'));
            audio.play();
        }
        this.chats = resp.n_total_registros;
    },
    checkSolicitudes() {
      this.todassolicitudes = true;
    },
    checkTrabajos() {
      this.todostrabajos = true;
    },
    async cargaralarmas() {
      const api = new PwgsApi();
      const apidos = new PwgsApi();

      try{
        this.alarmas = await api.get('alarmas');
      }
      catch(error){
        if (error == "Error: Token no válido") {
          apidos.get('logout');
          localStorage.removeItem('token');
          this.$router.push('/login');
        }
      }
      this.longitud = this.alarmas.length;
    },
    test() {
        this.$toast.removeAllGroups();
    },
    async cargarnovedades() {
      const api = new PwgsApi();
      const apidos = new PwgsApi();

      try{
        var datos = await api.get('novedades-pwgs');
        this.novedades = datos.datos;
        this.longitudnovedades = datos.n_total_registros;
      }
      catch(error){
        if (error == "Error: Token no válido") {
          apidos.get('logout');
          localStorage.removeItem('token');
          this.$router.push('/login');
        }
      }
    },
    async cargarChats(){
      const api = new PwgsApi();
      var resp = await api.get('chat-pwgs');
      this.chats = resp.n_total_registros;
    },
    cargartoast() {
      this.cargaralarmas();
      if (this.longitud>0 && localStorage.getItem('token')) {
        this.$toast.add({ severity: 'warn', summary: 'Alarmas', detail: 'Tienes ' + this.longitud + ' alarma/s por contestar', life: 30000 * 1 });
      }
      setInterval(() => {
        this.cargaralarmas();
        if (this.longitud>0 && localStorage.getItem('token')) {
          this.$toast.add({ severity: 'warn', summary: 'Alarmas', detail: 'Tienes ' + this.longitud + ' alarma/s por contestar', life: 30000 * 1 });
        }
      }, 100000 * 2);
    },
    cargartoastnovedades() {
      try{
        this.cargarnovedades();
        if (this.longitudnovedades>0 && localStorage.getItem('token')) {
          this.$toast.add({ severity: 'info', summary: 'Novedades', detail: 'Tienes ' + this.longitudnovedades + ' novedad/es por revisar', life: 30000 * 1 });
        }
        setInterval(() => {
          this.cargarnovedades();
          if (this.longitudnovedades>0 && localStorage.getItem('token')) {
            this.$toast.add({ severity: 'info', summary: 'Novedades', detail: 'Tienes ' + this.longitudnovedades + ' novedad/es por revisar', life: 30000 * 1 });
          }
        }, 150000 * 2);
      }
      catch(error){
        console.log(error);
      }
    },
    cargarvisible() {
      this.visible = false;
    },
    togglevisible() {
      this.visible = !this.visible;
    },
    cargarvisiblenovedades() {
      this.visiblenovedades = false;
    },
    togglevisiblenovedades() {
      this.visiblenovedades = !this.visiblenovedades;
    },
    esfirefox() {
      var isFirefox = typeof InstallTrigger !== 'undefined';

      if (isFirefox) {
        this.estilo = "padding-right:1.5% !important";
      } else {
        this.estilo = "";
      }
    },    
    async cargarConfirmadas(){
      const api = new PwgsApi;
      const datos = await api.post('avisos/citas-confirmadas');
      this.confirmadas = datos.datos;
      this.numero_confirmadas = datos.n_total_registros;
      localStorage.setItem("num_citas_confirmadas", this.numero_confirmadas);
    },
    async cargartrabajos() {
      /* get  modulos/pwgsapi/index.php/avisos/trabajos-no-revisados */
      const api = new PwgsApi;
      const datos = await api.post('avisos/trabajos-no-revisados',{'sortField':'', 'sortOrder':'1'});
      this.trabajos = datos.datos;
      this.numero_trabajos = datos.n_total_registros;
      localStorage.setItem("num_trabajos", this.numero_trabajos);
    },
    async cambiarUrgentes() {
      const api = new PwgsApi;
      if(this.asignacionesUrgentes==false){
        const datos = await api.post('avisos/asignaciones',{'sortField':'fecha_crear_servicio', 'sortOrder':'1',"urgente":1});
        this.asignaciones = datos.datos;
        this.numero_asignaciones = datos.n_total_registros;
        localStorage.setItem("num_asignaciones", this.numero_asignaciones);
      }else{
        this.cargarasignaciones();
      }
    },
    async cargarsolicitudes() {
      /* get  modulos/pwgsapi/index.php/avisos/solicitudes */
      const api = new PwgsApi;
      const datos = await api.post('avisos/solicitudes',{'sortField':'id_mensaje_cache', 'sortOrder':'0'});
      this.solicitudes = datos.datos;
      this.numero_solicitudes = datos.n_total_registros;
      localStorage.setItem("num_solicitudes", this.numero_solicitudes);
    },
    async cargarasignaciones() {
      /* get  modulos/pwgsapi/index.php/avisos/asignaciones */
      const api = new PwgsApi;
      const datos = await api.post('avisos/asignaciones',{'sortField':'fecha_crear_servicio', 'sortOrder':'1'});
      this.asignaciones = datos.datos;
      this.numero_asignaciones = datos.n_total_registros;
      localStorage.setItem("num_asignaciones", this.numero_asignaciones);


    },
    async salir(){
      const api = new PwgsApi();
      
      setTimeout(async() => {
        await api.get('logout');
                    }, 2500); 
    },
   async comprobarsesion(){
     const api = new PwgsApi();
     
     try {
       let result = await api.get('sesion-activa');
       if (result.descripcionInciencia == "Token no válido") {
                api.get('logout');
                localStorage.removeItem('token');
                this.$router.push('/login');
      }
     }
     catch (error) {
                    if (error == "Error: Token no válido") {
                        api.get('logout');
                        localStorage.removeItem('token');
                        this.$router.push('/login');
                    }
                    console.error('Error comprobando el token:', error);
                    this.logeado = false;
                }

    },
    cargartodo() {
      try{
      setInterval(() => {
      this.cargarasignaciones();
      this.cargarsolicitudes();
        this.cargartrabajos(); 
      }, 60000 * 2);
      }
      catch (error) {
        console.log("error123", error);
        if (error == "Error: Token no válido") {
          console.log("error345556");
          this.salir();
          this.$router.push('/login');
        }
      }
    },
    mostrarextendido() {
      this.extendido = !this.extendido;
    },
    monitorizarErrores(){

        var initOpts = {
            projectKey: "rMVhQP7dYnFONci3F4h7",
            ingestPoint: "https://monitorapp.grupoapuyen.net/ingest",
            defaultInputMode: 0,
            obscureTextNumbers: false,
            obscureTextEmails: true,
        };
        var startOpts = { userID: localStorage.getItem("nombreusuario")+"@"+localStorage.getItem("subdomain") }; 
        (function(A,s,a,y,e,r){
            r=window.OpenReplay=[e,r,y,[s-1, e]];
            s=document.createElement('script');s.src=A;s.async=!a;
            document.getElementsByTagName('head')[0].appendChild(s);
            r.start=function(){r.push([0])};
            r.stop=function(){r.push([1])};
            r.setUserID="function(id){r.push([2,id])}";
            r.setUserAnonymousID="function(id){r.push([3,id])}";
            r.setMetadata=function(k,v){r.push([4,k,v])};
            r.event=function(k,p,i){r.push([5,k,p,i])};
            r.issue=function(k,p){r.push([6,k,p])};
            r.isActive=function(){return false};
            r.getSessionToken=function(){};
        })("//static.openreplay.com/13.0.0/openreplay-assist.js",1,0,initOpts,startOpts);
    },
    async comprobarpermisos(){
      const api = new PwgsApi();
      let auxusuario = localStorage.getItem('usuario');
      const resppermisos = await api.get('usuarios/'+auxusuario+'/permisos');
      var permisos = resppermisos.datos;
      for(let permiso of permisos){
          this.permisosusuario.push(permiso.nombre_permiso);
      }
      if(this.permisosusuario.includes('Plataforma')){
        this.cargarSiguientes();
        this.cargarConfirmadas();
      }else{
        this.cargartrabajos(); 
        this.cargarsolicitudes();
      }
    },
    async cargarSiguientes(){
      this.siguientesCitas=[];
      const api = new PwgsApi();

      const datos = await api.post('avisos/siguientes-citas');
      this.siguientesCitas = datos.datos;
      this.num_siguientes_citas = datos.n_total_registros;
      localStorage.setItem("num_siguientes_citas",this.num_siguientes_citas);
    },
  },
  mounted() {
    this.comprobarWa();
    this.comprobarpermisos();
    this.cargartoast();
    //this.cargartoastnovedades();
    this.cargarasignaciones();
    this.cargartodo();
    this.monitorizarErrores();
    this.cargarChats();
    this.esfirefox();
    setInterval(() => this.comprobarChatsNuevo(), 60000);
    setInterval(() => this.comprobarsesion(), 60000);


  },
  created() {
    this.menu = this.$store.getters.menu;
    onMessage(messaging, (payload) => {
      console.log("entra!!!",payload);

      this.payload = payload;  
        //alert('Notificación recibida:');
        // Maneja la notificación 
    });
  }

}
</script>
<style scoped>
.fade-enter-active, .fade-leave-active {
  transition: opacity .5s;
}
.fade-enter, .fade-leave-to /* .fade-leave-active below version 2.1.8 */ {
  opacity: 0;
}
</style>