<template>
  <!-- Main Sidebar Container -->
  <aside class="main-sidebar sidebar-dark-primary elevation-4" style="z-index: 1000;">
    <!-- Brand Logo -->
    <a href="/escritorio" class="brand-link">
      <img src="../assets/logo.png" alt="PWGS Logo" class="brand-image" style="opacity: .8">
      <span class="brand-text">PWGS Quantum</span>
    </a>
    <!-- Sidebar -->
    <div class="sidebar">

      <!-- SidebarSearch Form -->
      <div class="form-inline">
        <div class="input-group" data-widget="sidebar-search">
          <input class="form-control form-control-sidebar" type="search" placeholder="Search" aria-label="Search">
          <div class="input-group-append">
            <button class="btn btn-sidebar">
              <i class="fas fa-search fa-fw"></i>
            </button>
          </div>
        </div>
      </div>
      <!-- Sidebar Menu -->
      <nav class="mt-2" style="display: flex; flex-direction: column; height: 100%;">
        <ul class="nav nav-pills nav-sidebar flex-column" data-widget="treeview" role="menu" data-accordion="false" style="flex-grow: 1;">
          <!-- Add icons to the links using the .nav-icon class
               with font-awesome or any other icon font library -->
          <!-- Menu principal -->

          <nav-menu :menu="menu"></nav-menu>
          

        </ul>
        <ul class="nav nav-pills nav-sidebar flex-column" data-widget="treeview" role="menu" data-accordion="false" style="flex-shrink: 0; bottom: 30px;">
          <li class="nav-item" v-if="isUpdateAvailable">
            <a href="#" @click="reloadPage" class="nav-link">
              <i class="nav-icon fas fa-sync-alt"></i>
              <p>Actualizar</p>
            </a>
          </li>
          <li class="nav-item">
            <p class="nav-link nav-version" style="font-size: smaller;">
              {{ appVersion }}
            </p>
          </li>
        </ul>
      </nav>
      <!-- /.sidebar-menu -->
    </div>
    <!-- /.sidebar -->
  </aside>
</template>
<style scoped>
.nav-version {
  font-size: 10px;
  margin-left: 5px;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}
</style>
<script>
export default {
  props: ['menu'],
  data() {
    return {
      autenticado: false,
      isUpdateAvailable: false,
      appVersion: localStorage.getItem('version') || '',
    }
  },
  created() {
    const token = localStorage.getItem('token');
    


    if (token) {
      this.autenticado = true;
    }
  },
  mounted() {
    
    this.checkForUpdates();
    setInterval(this.checkForUpdates, 60000); // Check every minute

  },

  beforeUnmount() {
    // Limpiar el intervalo cuando el componente se destruya
    clearInterval(this.interval);
  },

  methods: {
    checkForUpdates() {
      fetch('/version.txt')
        .then(response => response.text())
        .then(version => {
          const newVersion = version.trim();
          const currentVersion = localStorage.getItem('version');

          // Si no hay versión almacenada en localStorage, inicialízala
          if (!currentVersion) {
            localStorage.setItem('version', newVersion);
            this.appVersion = newVersion;
            this.isUpdateAvailable = false; // No muestra botón de actualizar porque es la primera carga
          } else if (currentVersion !== newVersion) {
            this.isUpdateAvailable = true; // Muestra botón de actualizar sólo si hay una nueva versión
            localStorage.setItem('version', newVersion);
            this.$toast.add({ severity: 'info', summary: 'Actualización', detail: 'Hay una actalización disponible de PWGS, presione sobre "Actualizar" en la parte inferior del menú. Recuerde guardar sus datos antes de actualizar.', life: 8000 });
          } else {
            this.isUpdateAvailable = false; // No hay actualización necesaria, versiones iguales
          }
        })
        .catch(error => {
          console.error('Error al cargar la versión:', error);
          this.appVersion = 'Error al cargar la versión';
        });
    },
    reloadPage() {
      window.location.reload(true);
    }
  }
}
</script>